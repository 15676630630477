
import { defineComponent, computed, ref, watch, PropType } from "vue";
import usePicker from "./usePicker.js";
type AlignType = "left" | "center" | "right";
export default defineComponent({
  name: "Select",
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type:String  ,
      default: "",
    },
    modelSelectValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    textAlign: {
      type: String as PropType<AlignType>,
      default: "right",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isClick: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const { select, showPicker } = usePicker(props, ctx);

    return {
      select,
      showPicker,
    };
  },
});
